//Localization service,
//This defines en and de, to allow translation
//It also stores the langukge is use in the localstorage
window.app.service('LocalizationService', ['$translate', function($translate) {

  class Localization {
    static get supportLangs () { return ['en', 'de', 'hu', 'po', 'ro', 'ru', 'tr', 'cs', 'hr', 'uk', 'es', 'it', 'fr']; }

    static get dateFormats () {
      return {
        'en': 'yyyy-MM-dd',
        'de': 'dd.MM.yyyy',
        'hu': 'dd.MM.yyyy',
        'po': 'dd.MM.yyyy',
        'ro': 'dd.MM.yyyy',
        'ru': 'dd.MM.yyyy',
        'tr': 'dd.MM.yyyy',
        'cs': 'dd.MM.yyyy',
        'hr': 'dd.MM.yyyy',
        'uk': 'dd.MM.yyyy',
        'es': 'dd.MM.yyyy',
        'it': 'dd.MM.yyyy',
        'fr': 'dd.MM.yyyy'
      }
    }

    static get dateWithWeekday () {
      return {
        'en': 'EEE yyyy-MM-dd',
        'de': 'EEE.dd.MM.yyyy',
        'hu': 'EEE.dd.MM.yyyy',
        'po': 'EEE.dd.MM.yyyy',
        'ro': 'EEE.dd.MM.yyyy',
        'ru': 'EEE.dd.MM.yyyy',
        'tr': 'EEE.dd.MM.yyyy',
        'cs': 'EEE.dd.MM.yyyy',
        'hr': 'EEE.dd.MM.yyyy',
        'uk': 'EEE.dd.MM.yyyy',
        'es': 'EEE.dd.MM.yyyy',
        'it': 'EEE.dd.MM.yyyy',
        'fr': 'EEE.dd.MM.yyyy'
      }
    }

    static get shortDateFortmats () {
      return {
        'en': 'yy-MM-dd',
        'de': 'dd.MM.yy',
        'hu': 'dd.MM.yy',
        'po': 'dd.MM.yy',
        'ro': 'dd.MM.yy',
        'ru': 'dd.MM.yy',
        'tr': 'dd.MM.yy',
        'cs': 'dd.MM.yy',
        'hr': 'dd.MM.yy',
        'uk': 'dd.MM.yy',
        'es': 'dd.MM.yy',
        'it': 'dd.MM.yy',
        'fr': 'dd.MM.yy'
      }
    }

    static get defaultLang () { return 'de'; }

    static localization(lang){
      if (this.supportLangs.indexOf(lang) === -1) {
        lang = this.defaultLang;
      }

      localStorage.setItem('lang', lang);
      localStorage.setItem('date_format', this.dateFormats[lang]);
      localStorage.setItem('short_date_format', this.shortDateFortmats[lang]);
      document.querySelector('html').setAttribute('lang', lang);
      $translate.use(lang).then(() => $translate.refresh());
    }
  }

  return Localization;
}]);
